import { createAction, createSlice } from "@reduxjs/toolkit";

import type { RootState } from "store";
import type { ObjectWithId } from "utils/ObjectWithId";

import type { PaymentsQueryParams } from "../models/Payment";
import type { Report } from "../models/Reports";

const initialState: {
  downloadedReportsIds: string[];
  polling: boolean;
  report: string | null;
  reports: Report[];
} = { reports: [], report: null, polling: true, downloadedReportsIds: [] };

export const slice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReports: (state, action) => {
      state.reports = action.payload;
    },
    setReport: (state, action) => {
      state.report = action.payload;
    },
    startPolling: (state) => {
      state.polling = true;
    },
    cancelPolling: (state) => {
      state.polling = false;
    },
    setDownloadedReportsIds: (state, action) => {
      state.downloadedReportsIds = [
        ...new Set([...state.downloadedReportsIds, action.payload]),
      ];
    },
    removeReport: (state, action) => {
      state.reports = state.reports.filter(
        (report) => report.id !== action.payload
      );
    },
  },
});

export const reportsOperationPaths = () => {
  return {
    generate: ["reports", "generate"],
    fetch: ["reports", "fetch"],
    download: ["reports", "download"],
    delete: ["reports", "delete"],
  };
};

export const {
  setReports,
  setReport,
  startPolling,
  cancelPolling,
  setDownloadedReportsIds,
  removeReport,
} = slice.actions;

export const generateReport = createAction<{
  filters: Omit<PaymentsQueryParams, "limit" | "page">;
}>("generateReport");
export const fetchReports = createAction("fetchReports");

export const downloadReport = createAction<ObjectWithId>("downloadReport");

export const deleteReport = createAction<ObjectWithId>("deleteReport");

export const selectReports = (state: RootState) => state.reports.reports;

export const selectReport = (state: RootState) => state.reports.report;

export const selectDownloadedReportsIds = (state: RootState) =>
  state.reports.downloadedReportsIds;

export const reportsReducer = slice.reducer;
