import {
  ACTION_CREATE,
  ACTION_DETAILS,
  ACTION_UPDATE,
} from "models/role/action";
import { SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const ProviderConfigCreate = lazyRetry(
  () => import("../pages/provider-configs/Create"),
  "ProviderConfigCreate"
);

const ProviderConfigDetails = lazyRetry(
  () => import("../pages/provider-configs/Details"),
  "CashManagementProviders"
);

const ProviderConfigEdit = lazyRetry(
  () => import("../pages/provider-configs/Edit"),
  "ProviderConfigEdit"
);

export const providersRoutes: Route[] = [
  {
    i18nName: "Create provider configuration",
    path: PAGE_URLS.providerConfigCreate,
    newTheme: true,
    component: ProviderConfigCreate,
    can: {
      do: ACTION_CREATE,
      on: SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP,
    },
  },
  {
    i18nName: "Provider configuration details",
    path: PAGE_URLS.providerConfigDetails,
    newTheme: true,
    component: ProviderConfigDetails,
    can: {
      do: ACTION_DETAILS,
      on: SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP,
    },
  },
  {
    i18nName: "Edit provider configuration",
    path: PAGE_URLS.providerConfigEdit,
    component: ProviderConfigEdit,
    newTheme: true,
    can: {
      do: ACTION_UPDATE,
      on: SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP,
    },
  },
];
