import { prepareCommonFiltersForApi } from "modules/common/utils/prepareCommonFiltersForApi";
import { pruneObject } from "modules/common/utils/pruneObject";

import type { CommonFilters } from "modules/common/utils/prepareCommonFiltersForApi";

export interface PaymentsFilters extends CommonFilters {}

export function preparePaymentsFilters(filters?: PaymentsFilters) {
  if (!filters) {
    return {};
  }

  const {
    amountMin,
    amountMax,
    country,
    licence,
    status,
    noBankChosen,
    ...restFilters
  } = filters;
  const param = prepareCommonFiltersForApi(restFilters);

  if (amountMin) {
    Object.assign(param, { "amount[gte]": amountMin });
  }

  if (amountMax) {
    Object.assign(param, { "amount[lte]": amountMax });
  }

  if (country) {
    Object.assign(param, { "bank[country]": country });
  }

  if (licence) {
    Object.assign(param, { "licence[]": licence });
  }

  if (status) {
    Object.assign(param, { "status[]": status });
  }

  if (noBankChosen) {
    Object.assign(param, { showAll: NO_BANK_CHOSEN_TRUTHY_VALUE });
  }

  return pruneObject(param);
}

const NO_BANK_CHOSEN_TRUTHY_VALUE = 1;
