import { Switch } from "react-router-dom";

import { AuthorizedRoute } from "components/routes/AuthorizedRoute";
import { UnauthorizedRoute } from "components/routes/UnauthorizedOnlyRoute";
import { UniversalRoute } from "components/routes/UniversalRoute";
import { useAppRoutes } from "hooks/useBuildChildRoutes";
import { useRedirectToTargetPathname } from "hooks/useRedirectToTargetPathname";
import { BasicLayout } from "layouts/Basic";
import { DashboardLayout } from "layouts/Dashboard";
import { TermsAndConditions } from "modules/user-settings";
import { Page404 } from "pages/Page404";
import { SentryRoute } from "sentry";

export function MainSwitch() {
  useRedirectToTargetPathname();

  const {
    switchAppRoutes,
    switchAuthRoutes,
    authPaths,
    appPaths,
    universalPaths,
    switchUniversalRoutes,
  } = useAppRoutes();

  return (
    <Switch>
      <UniversalRoute
        path={universalPaths}
        render={() => (
          <BasicLayout>
            <Switch>{switchUniversalRoutes}</Switch>
          </BasicLayout>
        )}
      />
      <UnauthorizedRoute
        path={authPaths}
        render={() => (
          <BasicLayout>
            <Switch>{switchAuthRoutes}</Switch>
          </BasicLayout>
        )}
      />
      <AuthorizedRoute
        path={appPaths}
        render={() => (
          <DashboardLayout key="layout">
            <TermsAndConditions />
            <Switch>
              {switchAppRoutes}
              <SentryRoute component={Page404} />
            </Switch>
          </DashboardLayout>
        )}
      />
    </Switch>
  );
}
