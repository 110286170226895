import { subject } from "@casl/ability";
import axios from "axios";

import { createFilterParams } from "api/helpers/createFilterParams";
import { getErrorDetails } from "api/helpers/getErrorDetails";
import { getPaginationInfoFromHeaders } from "api/helpers/pagination";
import { paramsSerializer } from "api/helpers/paramsSerializer";
import { portalApi, TAGS } from "api/rtkApi";
import { SUBJECT_PAYMENT } from "models/role/subject";
import { type UUID } from "utils";
import { preparePaginationParams } from "utils/preparePaginationParams";

import { preparePaymentsFilters } from "../utils/preparePaymentsFilters";
import { preparePaymentsPaginationParams } from "../utils/preparePaymentsPaginationParams";
import { prepareStatsPaymentsParams } from "../utils/preparePaymentsStatsParams";
import { transformPaymentResponse } from "../utils/transformPaymentResponse";

import type { AxiosRequestConfig } from "axios";

import type { PaginatedList } from "api/helpers/pagination";
import type { RTKMeta } from "models/RTKMeta";
import type { CreatedByParams } from "modules/common/models/CreatedBy";
import type { ObjectWithId } from "utils/ObjectWithId";
import type { PaginationParams } from "utils/preparePaginationParams";

import type { PaymentFilters } from "../models/Filters";
import type {
  OrderableColumns,
  Payment,
  PaymentsQueryParams,
} from "../models/Payment";

type GetPaymentResponse = Payment;
type GetPaymentRequest = ObjectWithId;

export async function getPayments(
  qsParams: PaymentsQueryParams & {
    orderBy: any;
  },
  options?: AxiosRequestConfig
): Promise<PaginatedList<Payment>> {
  const params = createFilterParams(qsParams, { pagination: true });

  try {
    const response = await axios({
      ...options,
      method: "GET",
      url: "/payments",
      params: {
        ...params,
        page: qsParams.page,
      },
      paramsSerializer,
    });
    const { data = {}, headers: responseHeaders = {} } = response;

    const { totalItems, currentPage, currentLimit } =
      getPaginationInfoFromHeaders(responseHeaders);

    return {
      list: (data ?? []).map((payment: Payment) =>
        subject(SUBJECT_PAYMENT, payment)
      ),
      totalItems,
      currentPage: currentPage + 1,
      currentLimit,
    };
  } catch (error) {
    throw getErrorDetails(error);
  }
}

type GetPaymentsRequest = {
  customerHierarchyId?: UUID;
  filters?: Partial<PaymentFilters>;
  id?: UUID;
  uniqueReference?: string;
} & PaginationParams<OrderableColumns> &
  CreatedByParams;

export type GetOldPaymentsResponse = {
  limit: number;
  list: Payment[];
  page: number;
  totalItems: number;
};

export type GetPaymentsResponse = {
  hasNext: boolean;
  hasPrev: boolean;
  list: Payment[];
};

export type GetPaymentStatsRequest = {
  customerHierarchyId?: UUID;
  endDate?: string | null;
  filters?: Partial<PaymentFilters>;
  search?: string;
  startDate?: string | null;
};

export type GetPaymentStatsResponse = {
  count: number;
};

const extendedApi = portalApi.injectEndpoints({
  endpoints: (builder) => ({
    getPayments: builder.query<GetPaymentsResponse, GetPaymentsRequest>({
      query: ({
        filters,
        startDate,
        endDate,
        customerHierarchyId,
        ...qsParams
      }) => ({
        url: "fuzebox/payments",
        params: Object.assign(
          {
            ...(customerHierarchyId && { customerHierarchyId }),
          },
          preparePaginationParams(preparePaymentsPaginationParams(qsParams)),
          preparePaymentsFilters({ ...filters, startDate, endDate })
        ),
      }),
      providesTags: [TAGS.PAYMENTS],
      transformResponse: (list: Payment[], meta: RTKMeta) => ({
        list,
        hasNext: Boolean(meta.response.headers.get("has-next")),
        hasPrev: Boolean(meta.response.headers.get("has-prev")),
      }),
    }),
    getPaymentsStats: builder.query<
      GetPaymentStatsResponse,
      GetPaymentStatsRequest
    >({
      query: ({
        filters,
        startDate,
        endDate,
        customerHierarchyId,
        search,
      }) => ({
        url: "fuzebox/payments/stats",
        params: {
          ...(customerHierarchyId && { customerHierarchyId }),
          ...prepareStatsPaymentsParams(search),
          ...preparePaymentsFilters({ ...filters, startDate, endDate }),
        },
      }),
    }),
    getOldPayments: builder.query<GetOldPaymentsResponse, GetPaymentsRequest>({
      query: ({
        filters,
        startDate,
        endDate,
        customerHierarchyId,
        ...qsParams
      }) => ({
        url: "/payments",
        params: {
          ...(customerHierarchyId && { customerHierarchyId }),
          ...preparePaginationParams(preparePaymentsPaginationParams(qsParams)),
          ...preparePaymentsFilters({ ...filters, startDate, endDate }),
        },
      }),
      providesTags: [TAGS.PAYMENTS],
      transformResponse: (list: Payment[], meta: RTKMeta) => ({
        list,
        totalItems: Number(meta.response.headers.get("total-items")),
        page: Number(meta.response.headers.get("page")),
        limit: Number(meta.response.headers.get("limit")),
      }),
    }),
    getPayment: builder.query<GetPaymentResponse, GetPaymentRequest>({
      query: ({ id }) => `payments/${id}`,
      keepUnusedDataFor: 30,
      providesTags: (_, __, { id }) => [{ type: TAGS.PAYMENT, id }],
      transformResponse: transformPaymentResponse,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPaymentQuery,
  useGetPaymentsQuery,
  useGetPaymentsStatsQuery,
  useGetOldPaymentsQuery,
} = extendedApi;
