import { Dashboard as DashboardIcon } from "@volt_developers/react-ui-components";

import { ACTION_DETAILS, ACTION_LIST } from "models/role/action";
import { SUBJECT_WORKFLOWS } from "models/role/subject";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const List = lazyRetryWithPreload(() => import("../pages/List"), "List");

const Details = lazyRetryWithPreload(
  () => import("../pages/Details"),
  "Details"
);

const Edit = lazyRetryWithPreload(() => import("../pages/Edit"), "Edit");

export const workflowsRoutes: Route = {
  i18nName: "List",
  path: PAGE_URLS.list,
  icon: DashboardIcon,
  component: List,
  newTheme: true,
  can: { do: ACTION_LIST, on: SUBJECT_WORKFLOWS },
  children: [
    {
      i18nName: "Details",
      path: PAGE_URLS.details,
      component: Details,
      newTheme: true,
      can: { do: ACTION_DETAILS, on: SUBJECT_WORKFLOWS },
    },
    {
      i18nName: "Edit",
      path: PAGE_URLS.edit,
      component: Edit,
      newTheme: true,
      can: { do: ACTION_DETAILS, on: SUBJECT_WORKFLOWS },
    },
  ],
};
