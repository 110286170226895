import {
  ACTION_CREATE,
  ACTION_DETAILS,
  ACTION_LIST,
  ACTION_UPDATE,
} from "models/role/action";
import { SUBJECT_CUSTOMER } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import { RedirectCheckoutBranding } from "../components/RedirectCheckoutBranding";
import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const AddressEdit = lazyRetry(
  () => import("../pages/address/Edit"),
  "CustomerManage"
);

const List = lazyRetryWithPreload(
  () => import("../pages/List"),
  "CustomerView"
);

const Edit = lazyRetry(() => import("../pages/Edit"), "CustomerManage");

const Create = lazyRetry(() => import("../pages/Create"), "CustomerManage");

const Details = lazyRetry(() => import("../pages/Details"), "CustomerDetails");

const Countries = lazyRetry(
  () => import("../pages/Countries"),
  "CustomerCountries"
);

const PublicKeys = lazyRetry(
  () => import("../pages/PublicKeys"),
  "CustomerPublicKeys"
);

export const customersRoutes: Route = {
  path: PAGE_URLS.list,
  i18nName: "Customers",
  component: List,
  showChildrenInSidebar: false,
  newTheme: true,
  can: {
    do: ACTION_LIST,
    on: SUBJECT_CUSTOMER,
  },
  children: [
    {
      i18nName: "Address",
      path: PAGE_URLS.addressEdit,
      newTheme: true,
      component: AddressEdit,
      can: {
        do: ACTION_UPDATE,
        on: SUBJECT_CUSTOMER,
      },
    },
    {
      i18nName: "Countries",
      path: PAGE_URLS.countries,
      newTheme: true,
      component: Countries,
    },
    {
      i18nName: "Create customer",
      path: PAGE_URLS.create,
      component: Create,
      newTheme: true,
      can: {
        do: ACTION_CREATE,
        on: SUBJECT_CUSTOMER,
        field: "id",
      },
    },
    {
      i18nName: "Edit customer",
      path: PAGE_URLS.edit,
      component: Edit,
      newTheme: true,
      can: {
        do: ACTION_UPDATE,
        on: SUBJECT_CUSTOMER,
      },
    },
    {
      i18nName: "Checkout Branding",
      path: PAGE_URLS.checkoutBrandingRedirect,
      component: RedirectCheckoutBranding,
      newTheme: true,
      hasNestedSwitch: true,
    },
    {
      i18nName: "Public keys",
      path: PAGE_URLS.publicKeys,
      newTheme: true,
      component: PublicKeys,
    },
    {
      i18nName: "Customer",
      path: PAGE_URLS.details,
      component: Details,
      newTheme: true,
      can: {
        do: ACTION_DETAILS,
        on: SUBJECT_CUSTOMER,
      },
      hasNestedSwitch: true,
    },
  ],
};
