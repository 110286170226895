import { isUUID } from "utils";

import type { PaginationParams } from "utils/preparePaginationParams";

import type { OrderableColumns } from "../models/Payment";

export interface PaymentsPaginationParams
  extends PaginationParams<OrderableColumns> {}

export function preparePaymentsPaginationParams({
  search,
  ...rest
}: PaymentsPaginationParams) {
  const trimmedSearchValue = search?.trim() ?? "";
  const searchIsNotUUID = !isUUID(trimmedSearchValue);

  if (searchIsNotUUID && search) {
    return {
      ...rest,
      referenceId: search,
    };
  }

  return {
    ...rest,
    search,
  };
}
