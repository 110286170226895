import { lazyRetry } from "utils/lazyRetry";

import type { Route } from "routes/RouteModel";

const UserSettings = lazyRetry(() => import("../pages/index"), "UserSettings");

export const userSettingsRoutes: Route = {
  i18nName: "Settings",
  path: "/settings",
  newTheme: true,
  hidden: true,
  forceDisplayInHeader: true,
  component: UserSettings,
};
