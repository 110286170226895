import { TRANSLATION_KEYS } from "./TranslationKeys";

import type { ToastProps } from "components/toasts/MessageToast";

export const TOASTS_IDS = {
  cashManagementAccountUpdateSuccess: "cash-management-account-update-success",
  createProviderConfigSuccess: "create-provider-config-success",
  updateProviderConfigSuccess: "update-provider-config-success",
  isxPayoutConfigurationUpdateSuccess:
    "connect-isx-payout-configuration-update-success",
  isxProviderNotFound: "isx-provider-not-found",
  productionSettlementBatchesTriggerSuccess:
    "production-settlement-batches-trigger-success",
  sandboxSettlementBatchesTriggerSuccess:
    "sandbox-settlement-batches-trigger-success",
  accountCreateSuccessToast: "account-create-success-toast",
  reportNotificationsSettingsUpdateSuccess:
    "report-notifications-settings-update-success",
  reportNotificationsSettingsTestSuccess:
    "report-notifications-settings-test-success",
  creditNotificationsSettingsTestSuccess:
    "credit-notifications-settings-test-success",
  creditNotificationsSettingsUpdateSuccess:
    "credit-notifications-settings-update-success",
} as const;

export const TOASTS = {
  validationError: (message: string): ToastProps => ({
    bodyContent: message,
    variant: "failure",
  }),
  isxPayoutConfigurationUpdateSuccess: {
    bodyContent: TRANSLATION_KEYS.isxPayoutConfigurationUpdated,
    variant: "success",
    config: {
      toastId: TOASTS_IDS.isxPayoutConfigurationUpdateSuccess,
    },
  },
  isxProviderNotFound: {
    bodyContent: TRANSLATION_KEYS.isxProviderNotFound,
    variant: "failure",
    config: {
      toastId: TOASTS_IDS.isxProviderNotFound,
    },
  },
  cashManagementAccountUpdateSuccess: {
    bodyContent: TRANSLATION_KEYS.cashManagementAccountHasBeenUpdated,
    variant: "success",
    config: {
      toastId: TOASTS_IDS.cashManagementAccountUpdateSuccess,
    },
  },
  reportNotificationsSettingsUpdateSuccess: {
    bodyContent:
      TRANSLATION_KEYS.reportNotificationsSettingsUpdatedSuccessfully,
    variant: "success",
    config: {
      toastId: TOASTS_IDS.reportNotificationsSettingsUpdateSuccess,
    },
  },
  reportNotificationsSettingsTestSuccess: {
    variant: "success",
    bodyContent: TRANSLATION_KEYS.weHaveReceivedTestNotification,
    config: {
      toastId: TOASTS_IDS.reportNotificationsSettingsTestSuccess,
    },
  },
  productionSettlementBatchesTriggerSuccess: (message: string): ToastProps => ({
    bodyContent: message,
    variant: "success",
    config: {
      toastId: TOASTS_IDS.productionSettlementBatchesTriggerSuccess,
    },
  }),
  sandboxSettlementBatchesTriggerSuccess: {
    bodyContent: TRANSLATION_KEYS.settlementBatchesSent,
    variant: "success",
    config: {
      toastId: TOASTS_IDS.sandboxSettlementBatchesTriggerSuccess,
    },
  },
  createProviderConfigSuccess: {
    bodyContent: TRANSLATION_KEYS.providerConfigUpdated,
    variant: "success",
    config: {
      toastId: TOASTS_IDS.createProviderConfigSuccess,
    },
  },
  updateProviderConfigSuccess: {
    bodyContent: TRANSLATION_KEYS.providerConfigUpdated,
    variant: "success",
    config: {
      toastId: TOASTS_IDS.createProviderConfigSuccess,
    },
  },
  accountCreateSuccess: {
    variant: "success",
    bodyContent: TRANSLATION_KEYS.virtualAccountCreatedSuccessfully,
    config: {
      toastId: TOASTS_IDS.accountCreateSuccessToast,
    },
  },
  creditNotificationsSettingsUpdateSuccess: {
    bodyContent:
      TRANSLATION_KEYS.creditNotificationsSettingsUpdatedSuccessfully,
    variant: "success",
    config: {
      toastId: TOASTS_IDS.creditNotificationsSettingsUpdateSuccess,
    },
  },
  creditNotificationsSettingsTestSuccess: {
    variant: "success",
    bodyContent: TRANSLATION_KEYS.weHaveReceivedTestNotification,
    config: {
      toastId: TOASTS_IDS.creditNotificationsSettingsTestSuccess,
    },
  },
} as const;

export const PAYOUT_NOTIFICATION_TOASTS = {
  createdNotifications: {
    variant: "success",
    bodyContent: TRANSLATION_KEYS.payoutNotificationsSettingsHaveBeenCreated,
  },
  updatedNotifications: {
    variant: "success",
    bodyContent: TRANSLATION_KEYS.payoutNotificationsSettingsHaveBeenUpdated,
  },
  testSuccess: {
    variant: "success",
    bodyContent: TRANSLATION_KEYS.weHaveReceivedTestNotification,
  },
} as const;
