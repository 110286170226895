/**
 * @deprecated use translations in the connect module
 */
export const cashManagement = {
  fields: {
    clientId: "Client ID",
    clientSecret: "Client secret",
    username: "Username",
    password: "Password",
    notificationSecret: "Notification secret",
    riskScore: "Risk score",
  },
  lowRisk: "Low Risk",
  highRisk: "High Risk",
};
