import { DateTime } from "luxon";

export const ESCAPE_COLON_SETTINGS = { keySeparator: ">", nsSeparator: "|" };

export function formatDateTime(value, format, lng) {
  const datTime = DateTime.fromISO(value);

  if (!datTime.isValid) {
    console.warn("Invalid date", value);

    return null;
  }

  return datTime.setLocale(lng).toLocaleString(DateTime[format]);
}

export function formatRelativeDateTime(value, lng) {
  const datTime = DateTime.fromISO(value);

  if (!datTime.isValid) {
    console.warn("Invalid date", value);

    return null;
  }

  return datTime.setLocale(lng).toRelative();
}

export function formatCurrency({ amount, currency = {} } = {}, lng) {
  const smallestUnitFactor = Math.pow(10, currency.decimal);

  const value = amount / smallestUnitFactor;

  return new Intl.NumberFormat(lng, {
    style: "currency",
    currency: currency.id,
  })
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case "currency":
        case "literal":
          return "";
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
}
