import { ACTION_CREATE, ACTION_LIST } from "models/role/action";
import { SUBJECT_CIRCUIT_BREAKER } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const Allowlist = lazyRetry(
  () => import("../pages/Allowlist"),
  "CircuitBreakerControlListView"
);

const AllowlistCreate = lazyRetry(
  () => import("../pages/AllowlistCreate"),
  "CircuitBreakerControlListManage"
);

export const allowlistRoute: Route = {
  component: Allowlist,
  i18nName: "Allowlist",
  newTheme: true,
  path: PAGE_URLS.allowlist,
  can: {
    do: ACTION_LIST,
    on: SUBJECT_CIRCUIT_BREAKER,
  },
  children: [
    {
      i18nName: "Add Allowlist Items",
      path: PAGE_URLS.allowlistCreate,
      newTheme: true,
      component: AllowlistCreate,
      can: {
        do: ACTION_CREATE,
        on: SUBJECT_CIRCUIT_BREAKER,
      },
    },
  ],
};
