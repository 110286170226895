import type { Account } from "modules/bank-accounts/models/Account";

import type {
  B4BProvider,
  BankingCirceProvider,
  CashManagementProvider,
  ClearJunctionProvider,
  VoltCashManagementBankProvider,
} from "./Provider";

export interface CashManagementProviderAccount {
  settlementEnabled: boolean;
  additionalConfiguration?: AdditionalConfiguration;
  cashManagementProvider?: CashManagementProvider;
}

export type AdditionalConfiguration =
  | B4BProvider
  | BankingCirceProvider
  | ClearJunctionProvider
  | VoltCashManagementBankProvider;

export interface CashManagementAccount extends Account {
  cashManagementProviderAccount: CashManagementProviderAccount;
  hasPayments: boolean;
}

export const PROPERTY_SANDBOX_ACCOUNT_BALANCE =
  "sandbox.providersAccountBalance";
export const PROPERTY_PRODUCTION_ACCOUNT_BALANCE =
  "production.providersAccountBalance";
