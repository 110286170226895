import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { useLocalStorage } from "react-use";

import { getActiveFiltersCount } from "../../utils/getActiveFiltersCount";
import { usePersistedFiltersToast } from "./usePersistedFiltersToast";

import type { Params } from "modules/common/hooks/useQueryString";

export interface UsePersistedFiltersParams<Filters extends Params = Params> {
  defaultFilters: Partial<Filters>;
  filters: Filters;
  key: string;
}

export function usePersistedFilters<Filters extends Params>({
  defaultFilters,
  filters,
  key,
}: UsePersistedFiltersParams<Filters>) {
  const [persistedFilters, setPersistedFilters] = useLocalStorage<Filters>(
    key,
    filters
  );
  const activeFiltersCount = persistedFilters
    ? getActiveFiltersCount(persistedFilters, OMMITED_KEYS)
    : 0;

  const resetPersistedFilters = () => {
    const filtersToSet = Object.fromEntries(
      Object.entries(persistedFilters!).map(([key, value]) =>
        OMMITED_KEYS.includes(key) ? [key, value] : [key, defaultFilters[key]]
      )
    ) as Filters;

    setPersistedFilters(filtersToSet);
  };

  usePersistedFiltersToast({
    activeFiltersCount,
    toastId: TOAST_ID,
    handleReset: resetPersistedFilters,
  });

  const listenStorageEvent = useCallback(
    (event: StorageEvent) => {
      const isSameFilters = event.key === key;

      if (isSameFilters && event.newValue) {
        setPersistedFilters(JSON.parse(event.newValue));
      }
    },
    [key, setPersistedFilters]
  );

  useEffect(() => {
    window.addEventListener("storage", listenStorageEvent);

    return () => {
      toast.dismiss(TOAST_ID);
      window.removeEventListener("storage", listenStorageEvent);
    };
  }, [listenStorageEvent]);

  const handleSetPersistedFilters = useCallback(
    (filters: Filters) => {
      if (toast.isActive(TOAST_ID)) {
        toast.dismiss(TOAST_ID);
      }

      setPersistedFilters(filters);
    },
    [setPersistedFilters]
  );

  return {
    // intended https://github.com/streamich/react-use/pull/2556
    persistedFilters: persistedFilters!,
    setPersistedFilters: handleSetPersistedFilters,
    resetPersistedFilters,
  };
}

const TOAST_ID = "filters-applied-toast";
export const FILTER_KEY_PREFIX = "filters";
const OMMITED_KEYS = ["startDate", "endDate", "query", "search"];

export function getPersistedFilterKey(key: string) {
  return `${FILTER_KEY_PREFIX}-${key}`;
}
