import { BankAccount as BankAccountIcon } from "@volt_developers/react-ui-components";

import {
  ACTION_CREATE,
  ACTION_DETAILS,
  ACTION_LIST,
  ACTION_UPDATE,
} from "models/role/action";
import { SUBJECT_ACCOUNT } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";

import type { Route } from "routes/RouteModel";

const AccountList = lazyRetry(() => import("../pages/List"), "BankAccountView");
const AccountDetails = lazyRetry(
  () => import("../pages/Details"),
  "BankAccountView"
);
const AccountEdit = lazyRetry(
  () => import("../pages/Edit"),
  "BankAccountManage"
);
const AccountCreate = lazyRetry(
  () => import("../pages/Create"),
  "BankAccountManage"
);

export const routes: Route = {
  i18nName: "Bank accounts",
  path: "/configuration/accounts",
  component: AccountList,
  icon: BankAccountIcon,
  newTheme: true,
  showChildrenInSidebar: false,
  can: {
    do: ACTION_LIST,
    on: SUBJECT_ACCOUNT,
  },
  children: [
    {
      i18nName: "Create bank account",
      path: "/configuration/accounts/create",
      component: AccountCreate,
      newTheme: true,
      can: {
        do: ACTION_CREATE,
        on: SUBJECT_ACCOUNT,
      },
    },
    {
      i18nName: "Bank account details",
      path: "/configuration/accounts/:id",
      component: AccountDetails,
      newTheme: true,
      can: {
        do: ACTION_DETAILS,
        on: SUBJECT_ACCOUNT,
      },
    },
    {
      i18nName: "Edit bank account",
      path: "/configuration/accounts/:id/edit",
      component: AccountEdit,
      newTheme: true,
      can: {
        do: ACTION_UPDATE,
        on: SUBJECT_ACCOUNT,
      },
    },
  ],
};
