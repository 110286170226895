import { Dashboard as DashboardIcon } from "@volt_developers/react-ui-components";

import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import type { Route } from "routes/RouteModel";

const Dashboard = lazyRetryWithPreload(
  () => import("../pages/Dashboard"),
  "Dashboard"
);

export const dashboardRoutes: Route = {
  i18nName: "Dashboard",
  path: "/",
  icon: DashboardIcon,
  component: Dashboard,
  newTheme: true,
};
