import { styled, Typography } from "@mui/material";
import { Card } from "@volt_developers/components";

import type { PropsWithChildren, ReactNode } from "react";

export interface ConfigureNotificationsCardProps extends PropsWithChildren {
  button: ReactNode;
  dropdownNavigation: ReactNode;
  title: ReactNode;
  "data-testid"?: string;
}

export function ConfigureConnectNotificationsCard({
  children,
  title,
  "data-testid": dataTestId,
  dropdownNavigation,
  button,
}: ConfigureNotificationsCardProps) {
  return (
    <Card.Wrapper data-testid={dataTestId} variant="elevated" gutterBottom>
      <Card.Body
        header={
          <HeaderWrapper>
            <Typography variant="h6">{title}</Typography>
            <HeaderWrapper>
              {button}
              {dropdownNavigation}
            </HeaderWrapper>
          </HeaderWrapper>
        }
      >
        {children}
      </Card.Body>
    </Card.Wrapper>
  );
}

const HeaderWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(1.5),
}));
