import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRendersCount } from "react-use";

import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { useTranslatedToasts } from "hooks/toasts/useTranslatedToasts";

interface UsePersistedFiltersToastParams {
  activeFiltersCount: number;
  handleReset: VoidFunction;
  toastId: string;
}

export function usePersistedFiltersToast({
  activeFiltersCount,
  toastId,
  handleReset,
}: UsePersistedFiltersToastParams) {
  const renderCount = useRendersCount();
  const { t } = useTranslation("common");

  const config = {
    filtersApplied: {
      bodyContent: t(COMMON_TRANSLATION_KEYS.filtersAppliedToThisView, {
        count: activeFiltersCount,
      }),
      variant: "info",
      config: {
        closeOnClick: true,
        toastId: toastId,
        autoClose: false,
      },
      type: "two-button",
      buttonTextPrimary: t(COMMON_TRANSLATION_KEYS.OK),
      buttonTextSecondary: t(COMMON_TRANSLATION_KEYS.reset),
    },
  } as const;
  const { showFiltersAppliedToast } = useTranslatedToasts(config, "common");

  const showToast = useCallback(
    () => showFiltersAppliedToast(() => void 0, handleReset),
    [handleReset, showFiltersAppliedToast]
  );

  useEffect(() => {
    const needToBeShowed = renderCount === 1 && activeFiltersCount > 0;

    if (needToBeShowed) {
      showToast();
    }
  }, [showToast, activeFiltersCount, renderCount]);
}
