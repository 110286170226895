import { createAction, createSlice } from "@reduxjs/toolkit";
import find from "lodash/find";

import { listFactory } from "store/listFactory";

import type { RootState } from "store/index";

import type { Payment } from "../models/Payment";

interface SliceState {
  currentLimit: number;
  currentPage: number;
  list: Payment[];
  totalItems: number;
}

const { slicePart, selectors } = listFactory<Payment>("payments");

const initialState = {
  ...slicePart.initialState,
  paymentMerchants: [],
} as SliceState;

export const slice = createSlice({
  ...slicePart,
  initialState,
  reducers: {
    ...slicePart.reducers,
  },
});

export const { setPaginatedList, setItem, removeList } = slice.actions;

export const fetchPayments = createAction<{}>("fetchPayments");

// selectPayment may return item that comes from loaded list item which
// does not contain all the fields. We have to wait for fetchPayments
// result that sets this artificial "__type" so that this code knows
// it is a full payment object
export const selectGetPaymentItem = (id: string) => (state: RootState) => {
  const foundPayment = find<Payment & { __type?: string }>(
    state.payments.list,
    ["id", id]
  );

  if (!foundPayment) {
    return undefined;
  }

  if ("__type" in foundPayment && foundPayment.__type === "getPayment") {
    return foundPayment;
  }

  return undefined;
};

export const {
  selectItem: selectPayment,
  selectPaginatedList: selectListData,
} = selectors;

export const paymentsReducer = slice.reducer;
