import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";

export interface ExtraNotificationFields {
  paymentNotificationSettingsFields?: string[];
  paymentReceivedNotificationSettingsFields?: string[];
}

export interface NotificationSettings extends ObjectWithId {
  active: boolean;
  createdAt: string;
  enabled: boolean;
  extraNotificationFields: ExtraNotificationFields;
  failedEmail: string;
  secret: string;
  url: string;
  paymentReceivalEnabled?: boolean;
}

export interface VerifyNotificationSettings
  extends Omit<NotificationSettings, "failedEmail" | "id"> {
  email: string;
  ownerId: UUID;
  sources: "verify";
}

export type NotificationSettingsUpdate = Pick<
  NotificationSettings,
  "enabled" | "extraNotificationFields" | "failedEmail" | "url"
>;

export type VerifyNotificationSettingsUpdate = Pick<
  VerifyNotificationSettings,
  "email" | "enabled" | "extraNotificationFields" | "url"
>;

export const PROPERTY_EXTRA_FIELDS = "extraNotificationFields";
