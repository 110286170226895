import isEmpty from "lodash/isEmpty";
import { fork, put, takeLatest } from "redux-saga/effects";

import { callApi } from "sagas/helpers/api";
import { setError, setSuccess, startLoading } from "store/appSlice";

import * as PaymentsApi from "../api/Payments";
import { fetchPayments, setPaginatedList } from "./payments.slice";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { PaymentsQueryParams } from "../models/Payment";

function* fetchPaymentsSaga({ payload }: PayloadAction<PaymentsQueryParams>) {
  const operationPath = "payments.list";

  yield put(startLoading(operationPath));
  try {
    const { licences, page = 1, statuses, ...rest } = payload;
    // @ts-ignore noImplicitAny
    const data = yield callApi(PaymentsApi.getPayments, {
      ...rest,
      page,
      statuses: !isEmpty(statuses) ? statuses : null,
      licences: !isEmpty(licences) ? licences : null,
    });

    yield put(setPaginatedList(data));
    yield put(setSuccess(operationPath));
  } catch (error) {
    console.error(error);
    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

function* watchFetchPayments() {
  yield takeLatest(fetchPayments.type, fetchPaymentsSaga);
}

export function* rootSaga() {
  yield fork(watchFetchPayments);
}
