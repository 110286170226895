import { isUUID } from "utils/uuid";

export function prepareStatsPaymentsParams(search = "") {
  const trimmedSearchValue = search?.trim();
  const searchIsNotUUID = !isUUID(trimmedSearchValue);

  if (searchIsNotUUID && search) {
    return {
      referenceId: trimmedSearchValue,
    };
  }

  if (trimmedSearchValue) {
    return {
      id: trimmedSearchValue,
    };
  }

  return {};
}
