import { ACTION_DETAILS, ACTION_LIST } from "models/role/action";
import { SUBJECT_CASH_MANAGEMENT_SETTLEMENT } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const List = lazyRetry(
  () => import("../pages/settlements/List"),
  "SettlementsList"
);

const Details = lazyRetry(
  () => import("../pages/settlements/Details"),
  "SettlementsDetails"
);

export const settlementsRoutes: Route[] = [
  {
    i18nName: "Settlements",
    path: PAGE_URLS.settlements,
    newTheme: true,
    component: List,
    can: {
      do: ACTION_LIST,
      on: SUBJECT_CASH_MANAGEMENT_SETTLEMENT,
    },
  },
  {
    i18nName: "Details",
    path: PAGE_URLS.settlementDetails,
    component: Details,
    newTheme: true,
    can: {
      do: ACTION_DETAILS,
      on: SUBJECT_CASH_MANAGEMENT_SETTLEMENT,
    },
  },
];
