import find from "lodash/find";
import findIndex from "lodash/findIndex";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { PartialShallow } from "lodash";

import type { List, PaginatedList } from "api/helpers/pagination";
import type { UUID } from "utils";

type CompositeId<T> = PartialShallow<T>;
type Id<T> = CompositeId<T> | UUID;

function isSimpleId<T>(id: Id<T>): id is UUID {
  return typeof id === "string";
}

function getItemQuery<T>(id: Id<T>) {
  return isSimpleId(id) ? (["id", id] as [string, UUID]) : id;
}

export function listFactory<T>(name: string): {
  selectors: {
    selectItem: (id: Id<T>) => (state: any) => T | undefined;
    selectList: (state: any) => T[];
    selectPaginatedList: (state: any) => PaginatedList<T>;
  };
  slicePart: {
    initialState: {
      currentLimit: number;
      currentPage: number;
      list: T[];
      totalItems: number;
    };
    name: string;
    reducers: {
      addItem: (state: any, action: PayloadAction<T>) => void;
      removeItem: (state: any, action: PayloadAction<Id<T>>) => void;
      removeList: (state: any) => void;
      setItem: (
        state: any,
        action: PayloadAction<{ id: Id<T>; item: T }>
      ) => void;
      setList: (state: any, action: PayloadAction<List<T>>) => void;
      setPaginatedList: (
        state: any,
        action: PayloadAction<PaginatedList<T>>
      ) => void;
      updateItem: (
        state: any,
        action: PayloadAction<{ id: Id<T>; itemPart: Partial<T> }>
      ) => void;
    };
  };
} {
  const initialState = {
    list: [] as T[],
    totalItems: 0,
    currentPage: 0,
    currentLimit: 0,
  };

  return {
    slicePart: {
      name,
      initialState,
      reducers: {
        setList: (state, action) => {
          const { list } = action.payload;

          state.list = list;
        },
        setPaginatedList: (state, action) => {
          const { list, totalItems, currentPage, currentLimit } =
            action.payload;

          state.list = list;
          state.totalItems = totalItems;
          state.currentPage = currentPage;
          state.currentLimit = currentLimit;
        },
        addItem: (state, action) => {
          const item = action.payload;

          state.list.unshift(item);
        },
        setItem: (state, action) => {
          const { id, item } = action.payload;
          const query = getItemQuery(id);
          const index = findIndex(state.list, query);

          if (index === -1) {
            state.list.unshift(item);

            return;
          }

          state.list.splice(index, 1, item);
        },
        updateItem: (state, action) => {
          const { id, itemPart } = action.payload;
          const query = getItemQuery(id);
          const item = find(state.list, query);

          if (item === undefined) {
            console.warn("item not found for query", query);

            return;
          }

          Object.assign(item, itemPart);
        },
        removeItem: (state, action) => {
          const id = action.payload;
          const query = getItemQuery(id);
          const index = findIndex(state.list, query);

          if (index === -1) {
            console.warn("item not found for query", query);

            return;
          }

          state.list.splice(index, 1);
        },
        removeList: (state) => {
          state.list = [];
        },
      },
    },
    selectors: {
      selectList: (state: any): T[] => {
        const { list } = state[name];

        return list;
      },
      selectItem:
        (id: Id<T>) =>
        (state: any): T | undefined => {
          const { list } = state[name];
          const query = getItemQuery(id);

          return find(list, query);
        },
      selectPaginatedList: (state: any): PaginatedList<T> => {
        const { list, totalItems, currentPage, currentLimit } = state[name];

        return { list, totalItems, currentPage, currentLimit };
      },
    },
  };
}
