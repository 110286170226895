import { ACTION_DETAILS } from "models/role/action";
import { SUBJECT_CHECKOUT } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const Details = lazyRetryWithPreload(
  () => import("../pages/Details"),
  "CheckoutBranding"
);

const Edit = lazyRetry(() => import("../pages/Edit"), "CheckoutBranding");

export const checkoutBrandingRoutes: Route[] = [
  {
    i18nName: "Update checkout branding",
    path: PAGE_URLS.edit,
    component: Edit,
    can: {
      do: ACTION_DETAILS,
      on: SUBJECT_CHECKOUT,
    },
  },
  {
    i18nName: "Checkout branding",
    path: PAGE_URLS.details,
    component: Details,
    can: {
      do: ACTION_DETAILS,
      on: SUBJECT_CHECKOUT,
    },
  },
];
