import type { CountryCode } from "../../../../models/CountryCode";
import type { UUID } from "../../../../utils";
import type { CreateCashManagementAccountForm } from "../../models/CreateCashManagementAccountForm";

export function prepareCashManagementAccountDataForApi(
  data: CreateCashManagementAccountForm & {
    customerId: UUID;
    country?: CountryCode;
  }
) {
  return {
    nickname: data.nickname,
    currency: data.currency?.id,
    country: data.country,
    cashManagementProviderAccount: {
      settlementEnabled: data.settlementEnabled,
      cashManagementProvider: data.cashManagementProvider?.id,
      additionalConfiguration: data.additionalConfiguration,
    },
    customer: data.customerId,
    ...(data.bsb && {
      bsb: data.bsb,
    }),
    ...(data.auAccountNumber && {
      auAccountNumber: data.auAccountNumber,
    }),
    ...(data.iban && {
      iban: data.iban,
    }),
    ...(data.swiftBic && {
      swiftBic: data.swiftBic,
    }),
    ...(data.accountNumber && {
      accountNumber: data.accountNumber,
    }),
    ...(data.routing && {
      routing: data.routing,
    }),
  };
}
