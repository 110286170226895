import isEmpty from "lodash/isEmpty";
import {
  call,
  delay,
  fork,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { callApi } from "sagas/helpers/api";
import {
  enqueueSnackbarError,
  enqueueSnackbarSuccess,
  setError,
  setSuccess,
  startLoading,
} from "store/appSlice";
import { downloadCSVFile, downloadFile } from "utils";

import * as ReportsApi from "../api/Reports";
import { REPORT_STATUS, REPORT_TYPES } from "../constants/Reports";
import { mapReportsResponse } from "../utils/mapReportsResponse";
import {
  cancelPolling,
  deleteReport,
  downloadReport,
  fetchReports,
  generateReport,
  removeReport,
  reportsOperationPaths,
  setDownloadedReportsIds,
  setReport,
  setReports,
  startPolling,
} from "./reports.slice";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { ObjectWithId } from "utils/ObjectWithId";

import type { PaymentsQueryParams } from "../models/Payment";
import type { ApiReport, DownloadedReport } from "../models/Reports";

const POLLING_DELAY = 10000;

function* generateReportSaga({
  payload,
}: PayloadAction<{ filters: Omit<PaymentsQueryParams, "limit" | "page"> }>) {
  const { filters } = payload;
  const { generate: generateReportPath } = reportsOperationPaths();

  yield put(startLoading(generateReportPath));

  try {
    yield callApi(ReportsApi.postGenerateReport, {
      ...filters,
      statuses: !isEmpty(filters?.statuses) ? filters?.statuses : null,
    });
    yield put(
      enqueueSnackbarSuccess({
        code: `Your report is currently being generated and we will let you know when it is available. 
          You can also download it later in the Reports section.`,
      })
    );
    yield put(setSuccess(generateReportPath));
    yield put(startPolling());
  } catch (error) {
    console.error(error);
    yield put(
      setError({
        path: generateReportPath,
        error,
      })
    );
    yield put(
      enqueueSnackbarError({
        code: error.exception.message,
      })
    );
  }
}

function* fetchReportsSaga() {
  const { fetch: fetchReportPath } = reportsOperationPaths();

  yield put(startLoading(fetchReportPath));

  try {
    const data: ApiReport[] = yield callApi(ReportsApi.getReports);

    yield put(setReports(mapReportsResponse(data)));
    yield put(setSuccess(fetchReportPath));
  } catch (error) {
    console.error(error);
    yield put(
      setError({
        path: fetchReportPath,
        error,
      })
    );
  }
}

function* downloadReportSaga({ payload }: PayloadAction<ObjectWithId>) {
  const { id } = payload;
  const { download: downloadReportPath } = reportsOperationPaths();

  yield put(startLoading(downloadReportPath));

  try {
    const report: DownloadedReport = yield callApi(
      ReportsApi.postDownloadReport,
      { id }
    );
    const approbateHandler =
      report.type === REPORT_TYPES.LINK ? downloadFile : downloadCSVFile;

    approbateHandler(report.data);

    yield put(setReport(id));
    yield put(setDownloadedReportsIds(id));
    yield put(setSuccess(downloadReportPath));
  } catch (error) {
    console.error(error);
    yield put(
      setError({
        path: downloadReportPath,
        error,
      })
    );
    yield put(
      enqueueSnackbarError({
        code: "Cannot download report now. Please, try again later",
      })
    );
  }
}

export function* fetchReportsPollSaga() {
  let loop = true;
  const { fetch: fetchReportPath } = reportsOperationPaths();

  while (loop) {
    try {
      const data: ApiReport[] | undefined = yield callApi(
        ReportsApi.getReports
      );

      yield put(setReports(mapReportsResponse(data ?? [])));
      yield put(setSuccess(fetchReportPath));
      const isAnyReportInProgress =
        data?.some(
          (report) =>
            report.status === REPORT_STATUS.IN_PROGRESS ||
            report.status === REPORT_STATUS.REQUESTED
        ) ?? false;

      if (data?.length === 0 || !isAnyReportInProgress) {
        if (process.env.NODE_ENV === "test") {
          loop = false;
        }

        yield put(cancelPolling());
      }

      yield delay(POLLING_DELAY);
    } catch (error) {
      console.error(error);
      yield put(
        setError({
          path: fetchReportPath,
          error,
        })
      );
      yield put(
        enqueueSnackbarError({
          code: "List of reports is not available now. Please, try again later",
        })
      );
      yield put(cancelPolling());
    }
  }
}

export function* watchFetchReportsPollSaga() {
  while (true) {
    yield take(startPolling);
    yield race([call(fetchReportsPollSaga), take(cancelPolling)]);
  }
}

function* deleteReportSaga({ payload }: PayloadAction<ObjectWithId>) {
  const { id } = payload;
  const { delete: deleteReportPath } = reportsOperationPaths();

  yield put(startLoading(deleteReportPath));

  try {
    yield callApi(ReportsApi.deleteReport, { id });
    yield put(removeReport(id));
    yield put(setSuccess(deleteReportPath));
  } catch (error) {
    yield put(
      setError({
        path: deleteReportPath,
        error,
      })
    );
  }
}

export function* rootSaga() {
  yield takeEvery(deleteReport.type, deleteReportSaga);
  yield takeLatest(downloadReport.type, downloadReportSaga);
  yield takeLatest(fetchReports.type, fetchReportsSaga);
  yield takeLatest(generateReport.type, generateReportSaga);
  yield fork(watchFetchReportsPollSaga);
}
