import type { UUID } from "utils";

const BASE_URL = "/connect";
const ACCOUNTS_BASE_URL = `${BASE_URL}/accounts`;
const CONFIGURATION_BASE_URL = `${BASE_URL}/configuration`;
const PROVIDER_CONFIGS_BASE_URL = `${BASE_URL}/provider-configs`;
const SETTLEMENTS_BASE_URL = "/transactions/settlements";
const CUSTOMER_BASE_URL = "/configuration/customers";

export const URL_PARAMS = {
  CONFIGURE: "configure",
};

export const PAGE_URLS = {
  core: BASE_URL,
  settlements: SETTLEMENTS_BASE_URL,
  settlementDetails: `${SETTLEMENTS_BASE_URL}/:id`,
  accounts: `ACCOUNTS_BASE_URL`,
  providers: `${BASE_URL}/providers`,
  configuration: CONFIGURATION_BASE_URL,
  accountDetails: `${ACCOUNTS_BASE_URL}/:accountId`,
  accountCreate: `${ACCOUNTS_BASE_URL}/create`,
  isxPayoutConfiguration: `${CONFIGURATION_BASE_URL}/isx-payout`,
  providerConfigDetails: `${PROVIDER_CONFIGS_BASE_URL}/:configId`,
  payoutNotificationsConfiguration: `${CONFIGURATION_BASE_URL}?${URL_PARAMS.CONFIGURE}=payoutNotifications`,
  reportNotificationsConfiguration: `${CONFIGURATION_BASE_URL}?${URL_PARAMS.CONFIGURE}=reportNotifications`,
  creditNotificationsConfiguration: `${CONFIGURATION_BASE_URL}?${URL_PARAMS.CONFIGURE}=creditNotifications`,
  providerConfigCreate: `${PROVIDER_CONFIGS_BASE_URL}/create`,
  providerConfigEdit: `${PROVIDER_CONFIGS_BASE_URL}/:configId/edit`,
  getLinkToAccountDetails: (accountId: UUID) =>
    `${ACCOUNTS_BASE_URL}/${accountId}`,
  getLinkToProviderConfigDetails: (configId: UUID) =>
    `${PROVIDER_CONFIGS_BASE_URL}/${configId}`,
  getLinkToCreateProviderConfig: (providerId: UUID) =>
    `${PROVIDER_CONFIGS_BASE_URL}/create?providerId=${providerId}`,
  getLinkToEditProviderConfig: (configId: UUID) =>
    `${PROVIDER_CONFIGS_BASE_URL}/${configId}/edit`,
  getSettlementDetails: (id: UUID) => `${SETTLEMENTS_BASE_URL}/${id}`,
  getLinkToCustomerDetails: (id: UUID) => `${CUSTOMER_BASE_URL}/${id}`,
};
