import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

import { FormButtons } from "components/form/FormButtons";
import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";

import { PAGE_URLS } from "../../../constants/PageUrls";

export interface ReportNotificationsFormButtonsProps {
  isLoading: boolean;
}

export function ReportNotificationsFormButtons({
  isLoading,
}: ReportNotificationsFormButtonsProps) {
  const { t } = useTranslation("common");

  return (
    <Wrapper>
      <FormButtons
        cancelUrl={PAGE_URLS.configuration}
        isLoading={isLoading}
        submitLabel={t(COMMON_TRANSLATION_KEYS.confirm)}
      />
    </Wrapper>
  );
}

const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "end",
});
