import { Dashboard as DashboardIcon } from "@volt_developers/react-ui-components";

import { lazyRetry } from "utils/lazyRetry";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import { PAGE_URLS } from "../constants";

import type { Route } from "routes/RouteModel";

const List = lazyRetryWithPreload(() => import("../pages/List"), "List");

const PayoutPage = lazyRetry(() => import("../pages/payout"), "PayoutPage");

const Create = lazyRetry(() => import("../pages/Create"), "Create");

export const payoutsRoutes: Route = {
  i18nName: "List",
  path: PAGE_URLS.list,
  icon: DashboardIcon,
  component: List,
  newTheme: true,
  children: [
    {
      i18nName: "Create",
      path: PAGE_URLS.create,
      component: Create,
      newTheme: true,
    },
    {
      i18nName: "Payout",
      path: PAGE_URLS.details,
      component: PayoutPage,
      newTheme: true,
      hasNestedSwitch: true,
    },
  ],
};
