import { subject } from "@casl/ability";

import { SUBJECT_PAYMENT } from "models/role/subject";

import { STATUS_NEW_PAYMENT } from "../constants/paymentStatuses";

import type { Payment } from "../models/Payment";

export function transformPaymentResponse(payment: Payment): Payment {
  const statusUpdates = [
    {
      createdAt: payment.createdAt,
      newStatus: STATUS_NEW_PAYMENT,
    },
    ...(payment.statusUpdates ?? []),
  ];

  return subject(SUBJECT_PAYMENT, Object.assign(payment, { statusUpdates }));
}
