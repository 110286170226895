import { createAction, createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";
import _set from "lodash/set";

import { ENV_SANDBOX } from "constants/environment";
import {
  ERROR_STATE,
  LOADING_STATE,
  SUCCESS_STATE,
} from "constants/operationsState";
import { generateUniqueId } from "utils";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { APPLICATION_ENV} from "constants/environment";
import type { GlobalFilteredCustomer } from "models/GlobalFilteredCustomer";
import type { UUID } from "utils";

import type { RootState } from "./index";

type OperationState =
  | { type: typeof ERROR_STATE; error?: any }
  | { type: typeof LOADING_STATE }
  | { type: typeof SUCCESS_STATE };

export type Snackbar = {
  key: number | string;
  dismissed?: boolean;
  dynamicContent?: boolean;
  i18nMessage?: string;
  message?: string;
  options?: object;
};

export type SliceState = {
  environment: APPLICATION_ENV;
  globalFilteredCustomer: GlobalFilteredCustomer | null;
  operationsState: Record<string, OperationState | null>;
  snackbars: Snackbar[];
  snackbarsKeys: { key: string; value: number | string }[];
  targetPathname: string | null;
};

export const initialState: SliceState = {
  environment: ENV_SANDBOX,
  globalFilteredCustomer: null,
  operationsState: {},
  snackbars: [],
  snackbarsKeys: [],
  targetPathname: null,
};

const enqueueSnackbarReducer = (
  state: SliceState,
  {
    payload,
  }: {
    payload: {
      dynamicContent?: boolean;
      i18nMessage?: string;
      key?: number | string;
      message?: string;
      options?: object;
    };
  }
) => {
  const { dynamicContent, key, ...snackbar } = payload;
  const snackKey = key ?? generateUniqueId();

  if (key) {
    state.snackbarsKeys.push({ key: String(key), value: snackKey });
  }

  state.snackbars.push({
    dynamicContent,
    key: snackKey,
    ...snackbar,
  });
};

export const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    startLoading: (state, action) => {
      _set(state.operationsState, action.payload, { type: LOADING_STATE });
    },
    setError(state, { payload }) {
      const { path, error } = payload;

      _set(state.operationsState, path, { type: ERROR_STATE, error });
    },
    setSuccess(state, action) {
      _set(state.operationsState, action.payload, { type: SUCCESS_STATE });
    },
    clearOperation(state, action) {
      _set(state.operationsState, action.payload, null);
    },
    clearResult(state, action) {
      const path = action.payload;
      const operationState = _get(state.operationsState, path);

      if (
        operationState &&
        [ERROR_STATE, SUCCESS_STATE].includes(operationState.type)
      ) {
        _set(state.operationsState, path, null);
      }
    },
    enqueueSnackbar: enqueueSnackbarReducer,
    closeSnackbar: (state, { payload }) => {
      const { dismissAll, key } = payload;

      state.snackbars = state.snackbars.map((snackbar) =>
        dismissAll || snackbar.key === key
          ? { ...snackbar, dismissed: true }
          : { ...snackbar }
      );
    },
    removeSnackbar: (state, { payload: key }) => {
      state.snackbars = state.snackbars.filter(
        (snackbar) => snackbar.key !== key
      );
    },
    enqueueSnackbarError(
      state,
      { payload }: { payload: { code?: string; message?: string } }
    ) {
      const { code, message } = payload;

      enqueueSnackbarReducer(state, {
        payload: {
          message,
          i18nMessage: code,
          options: {
            variant: "error",
          },
        },
      });
    },
    enqueueSnackbarInfo(
      state,
      {
        payload,
      }: {
        payload: {
          code: string;
          dynamicContent?: boolean;
          key?: UUID;
          persist?: boolean;
          preventDuplicate?: boolean;
        };
      }
    ) {
      const {
        code,
        dynamicContent,
        key,
        persist = false,
        preventDuplicate = false,
      } = payload;

      enqueueSnackbarReducer(state, {
        payload: {
          dynamicContent,
          i18nMessage: code,
          key,
          options: {
            persist,
            preventDuplicate,
            variant: "info",
          },
        },
      });
    },
    enqueueSnackbarSuccess(state, { payload }: { payload: { code: string } }) {
      const { code } = payload;

      enqueueSnackbarReducer(state, {
        payload: {
          i18nMessage: code,
          options: {
            variant: "success",
          },
        },
      });
    },
    enqueueSnackbarSticky(
      state,
      { payload }: { payload: { code?: string; message?: string } }
    ) {
      const { code, message } = payload;

      enqueueSnackbarReducer(state, {
        payload: {
          message,
          i18nMessage: code,
          options: {
            variant: "sticky",
          },
        },
      });
    },
    setEnvironment: (state, action) => {
      state.environment = action.payload;
    },
    setGlobalFilteredCustomer: (
      state,
      { payload }: { payload: { allIds: UUID[], id: UUID; name: string; } }
    ) => {
      state.globalFilteredCustomer = payload;
    },
    appendIdToGlobalFilteredCustomerAllIds: (
      state,
      {
        payload,
      }: { payload: { createdCustomerId: UUID; parentCustomerId: UUID | null } }
    ) => {
      const { createdCustomerId, parentCustomerId } = payload;

      if (
        parentCustomerId &&
        state.globalFilteredCustomer?.allIds.includes(parentCustomerId)
      ) {
        state.globalFilteredCustomer?.allIds.push(createdCustomerId);
      }
    },
    setTargetPathname: (state, { payload }: PayloadAction<string>) => {
      state.targetPathname = payload;
    },
    clearTargetPathname: (state) => {
      state.targetPathname = null;
    },
    clearGlobalFilteredCustomer: (state) => {
      state.globalFilteredCustomer = null;
    },
    closePermitedSnackbar: (state, { payload }: PayloadAction<string>) => {
      const snackbarToClose = state.snackbarsKeys.find(
        ({ key }) => key === payload
      );

      if (!snackbarToClose) {
        return;
      }

      const snackbar = state.snackbars.find(
        ({ key }) => key === snackbarToClose.value
      );

      if (snackbar) {
        snackbar.dismissed = true;
      }

      state.snackbarsKeys = state.snackbarsKeys.filter(
        ({ key }) => key !== payload
      );
    },
    updatePermitedSnackbar: (
      state,
      { payload }: PayloadAction<{ code: string; key: string }>
    ) => {
      const snackbarToUpdateIndex = state.snackbars.findIndex(
        (snackbar) => snackbar.key === payload.key
      );

      state.snackbars[snackbarToUpdateIndex] = {
        ...state.snackbars[snackbarToUpdateIndex],
        i18nMessage: payload.code,
      };
    },
  },
});

export const {
  startLoading,
  setError,
  setSuccess,
  clearOperation,
  clearResult,
  enqueueSnackbar,
  enqueueSnackbarError,
  enqueueSnackbarInfo,
  enqueueSnackbarSuccess,
  closePermitedSnackbar,
  enqueueSnackbarSticky,
  closeSnackbar,
  removeSnackbar,
  setEnvironment,
  setGlobalFilteredCustomer,
  appendIdToGlobalFilteredCustomerAllIds,
  clearGlobalFilteredCustomer,
  updatePermitedSnackbar,
  setTargetPathname,
  clearTargetPathname,
} = slice.actions;

export const init = createAction("init");
export const toggleEnvironment = createAction<{ allRoutes: string[] }>(
  "toggleEnvironment"
);

export const selectEnvironment = (state: RootState) => state.app.environment;

export type OperationPath =
  | (string | null | undefined)[]
  | string
  | null
  | undefined;

export const selectOperationState =
  (path: OperationPath) => (state: RootState) => {
    if (
      typeof path === "string" ||
      path?.every((subpath): subpath is string => Boolean(subpath))
    ) {
      return _get(state.app.operationsState, path);
    }

    return undefined;
  };

export const selectGlobalFilteredCustomer = (state: RootState) =>
  state.app.globalFilteredCustomer;

export const selectGlobalFilteredCustomerIds = (state: RootState) =>
  state.app.globalFilteredCustomer?.allIds;

export const selectTargetPathname = (state: RootState) =>
  state.app.targetPathname;

export const appSlice = slice.reducer;
