import { ACTION_CREATE, ACTION_DETAILS } from "models/role/action";
import { SUBJECT_CASH_MANAGEMENT_ACCOUNT } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const CreateAccount = lazyRetry(
  () => import("../pages/accounts/Create"),
  "CreateAccount"
);

const AccountDetails = lazyRetry(
  () => import("../pages/accounts/Details"),
  "AccountDetails"
);

export const accountsRoutes: Route[] = [
  {
    i18nName: "Create virtual account",
    path: PAGE_URLS.accountCreate,
    newTheme: true,
    component: CreateAccount,
    can: {
      do: ACTION_CREATE,
      on: SUBJECT_CASH_MANAGEMENT_ACCOUNT,
    },
  },
  {
    i18nName: "Virtual account details",
    path: PAGE_URLS.accountDetails,
    component: AccountDetails,
    newTheme: true,
    can: {
      do: ACTION_DETAILS,
      on: SUBJECT_CASH_MANAGEMENT_ACCOUNT,
    },
  },
];
