import i18n from "i18next";
import { DateTime } from "luxon";

import { currenciesMap, defaultCurrency } from "utils/currencies";

import type { Currency } from "models/Currency";

import type { ApiReport } from "../models/Reports";

interface Properties {
  country?: string | null;
  currency?: Partial<Currency>;
  maxAmount?: number | null;
  minAmount?: number | null;
  statuses?: string | string[];
}

const stringifyLuxonDates = (createdAt: { gte?: string; lte?: string }) => {
  const { gte, lte } = createdAt;
  let startDate = gte
    ? DateTime.fromFormat(gte, "yyyyLLddHHmmss").toISO()
    : null;
  let endDate = lte ? DateTime.fromFormat(lte, "yyyyLLddHHmmss").toISO() : null;

  return { startDate, endDate };
};

const formatValue = (amount: number, currency: Currency) => {
  const formatedValue = i18n
    .t("common:CURRENCY", {
      data: {
        amount: amount,
        currency,
      },
    })
    .replaceAll(",", "");
  const [integerPart, decimalPart = "0000"] = formatedValue.split(".");
  const correctDecimalPart = decimalPart
    .padEnd(currency.decimal, "0")
    .substring(0, currency.decimal);

  return Number([integerPart, correctDecimalPart].join("."));
};

const createParameters = (filters: any) => {
  let properties: Properties = {};
  let currency: Currency | undefined;

  if (filters.currency) {
    const searchedCurrency = currenciesMap.get(filters.id) ?? defaultCurrency;

    currency = searchedCurrency;
    properties.currency = searchedCurrency;
  } else {
    currency = defaultCurrency;
  }

  if (filters?.amount?.gte) {
    const formatedValue = formatValue(filters.amount.gte, currency);

    properties.minAmount = formatedValue;
  }

  if (filters?.amount?.lte && currency) {
    const formatedValue = formatValue(filters.amount.lte, currency);

    properties.maxAmount = formatedValue;
  }

  if (filters.bank) {
    properties.country = filters.bank.country;
  }

  if (filters.status) {
    properties.statuses = filters.status;
  }

  return properties;
};

export const mapReportsResponse = (reports: ApiReport[]) => {
  const mappedReports = reports.map((report) => {
    const filters = createParameters(report.filters);
    let dates = {};

    if (report.filters.createdAt) {
      dates = stringifyLuxonDates(report.filters.createdAt);
    }

    return {
      id: report.id,
      status: report.status,
      parameters: {
        ...dates,
        ...filters,
      },
    };
  });

  return mappedReports;
};
