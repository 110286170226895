import { LOCAL_STORAGE_KEYS } from "constants/LocalStorageItems";
import { useFeatureFlag } from "hooks/useFeatureFlag";

export function useSettlementsListFeatureFlag() {
  const hasSettlementListFeatureFlag = useFeatureFlag(
    LOCAL_STORAGE_KEYS.settlementsList
  );

  return hasSettlementListFeatureFlag;
}
