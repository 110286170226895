/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  accountIdentification: "Account identification",
  accountNumber: "Account number",
  accountProvider: "Account provider",
  accounts: "Accounts",
  automatic: "Automatic",
  balance: "Balance",
  bsb: "BSB",
  clearJunctionBalance: "Clear Junction Balance",
  clearJunctionBalanceCurrency: "Clear Junction Balance ({{currency}})",
  companyUuid: "Company UUID",
  connectAccounts: "Connect accounts",
  currency: "Currency",
  enable: "Enable",
  iban: "IBAN",
  minimumReserve: "Minimum reserve",
  nextSettlement: "Next settlement",
  pixKey: "Pix Key",
  refreshBalance: "Refresh Balance",
  settle: "Settle",
  settlement: "Settlement",
  settlementAmount: "Settlement amount",
  sortCode: "Sort code",
  swiftBic: "Swift code (BIC)",
  threshold: "Threshold",
  voltCashManagementBalance: "Volt Cash Management Balance",
  voltCashManagementBalanceCurrency:
    "Volt Cash Management Balance ({{currency}})",
};
