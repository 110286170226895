import { styled } from "@mui/material";
import { Sidebar as VoltSidebar } from "@volt_developers/components";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { useSidebarItem } from "./useSidebarItem";

import type { SidebarConfigItem } from "./sidebarConfig";

interface SidebarItemProps extends SidebarConfigItem {}

export function SidebarItem({
  id,
  title,
  icon,
  permissions,
  permissionOneOf,
  children,
  url = "/",
  isActive,
  "data-testid": dataTestId,
}: SidebarItemProps) {
  const location = useLocation();
  const { t } = useTranslation("common");

  const isGroup = children && children.length > 0;
  const isDefaultOpen = isActive?.(url, location.pathname);

  const { hasAccess, isGroupOpen, setIsGroupOpen } = useSidebarItem({
    permissions,
    permissionOneOf,
    itemId: id,
    isDefaultOpen: isGroup ? isDefaultOpen : undefined,
  });

  if (!hasAccess) {
    return null;
  }

  if (isGroup) {
    return (
      <VoltSidebar.Group
        data-testid={dataTestId}
        icon={icon}
        isActive={isActive?.(url, location.pathname)}
        isDefaultOpen={isDefaultOpen || isGroupOpen}
        label={t(title)}
        items={children.map((sidebarItem) => (
          <SidebarItem {...sidebarItem} key={sidebarItem.id} />
        ))}
        onExpand={setIsGroupOpen}
      />
    );
  }

  return (
    <VoltSidebar.Link
      data-testid={dataTestId}
      icon={icon}
      isActive={isActive?.(url, location.pathname)}
      label={t(title)}
      linkComponent={<StyledLink to={url} />}
    />
  );
}

const StyledLink = styled(RouterLink)({
  textDecoration: "none",
});
