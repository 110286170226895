import { portalApi, TAGS } from "api/rtkApi";
import { SERVICES } from "constants/environment";

import type { UUID } from "utils";

import type {
  CashManagementNotificationSettings,
  CreditNotifications,
  PayoutNotifications,
  ReportNotifications,
} from "../models/Notifications";

type ObjectWithCustomerId = {
  customerId: UUID;
};

type PayoutNotificationSetup = {
  url: string;
  enabled: boolean;
} & ObjectWithCustomerId;

type ReportNotificationSetup = {
  enabled: boolean;
  email: string;
  url: string;
  customerId: UUID;
} & ObjectWithCustomerId;

type CreditNotificationSetup = {
  url: string;
  enabled: boolean;
  email: string;
} & ObjectWithCustomerId;

type GetReportNotificationsRequest = ObjectWithCustomerId;
type GetReportNotificationsResponse = ReportNotifications;
type PostReportNotificationsResponse = CashManagementNotificationSettings;
type PostReportNotificationsRequest = ReportNotificationSetup;
type PutReportNotificationsResponse = CashManagementNotificationSettings;
type PutReportNotificationsRequest = ReportNotificationSetup;
type PostTestReportNotificationsRequest = ObjectWithCustomerId;
type PostTestReportNotificationsResponse = ObjectWithCustomerId;
type GetPayoutNotificationsRequest = ObjectWithCustomerId;
type GetPayoutNotificationsResponse = PayoutNotifications;
type PutPayoutNotificationsRequest = PayoutNotificationSetup;
type PutPayoutNotificationsResponse = PayoutNotifications;
type PostPayoutNotificationsRequest = PayoutNotificationSetup;
type PostPayoutNotificationsResponse = PayoutNotifications;
type PostTestPayoutNotificationsRequest = ObjectWithCustomerId;
type PostTestPayoutNotificationsResponse = ObjectWithCustomerId;
type GetCreditNotificationsRequest = ObjectWithCustomerId;
type GetCreditNotificationsResponse = CreditNotifications;
type PostCreditNotificationsRequest = CreditNotificationSetup;
type PostCreditNotificationsResponse = CreditNotifications;
type PutCreditNotificationsRequest = CreditNotificationSetup;
type PutCreditNotificationsResponse = CreditNotifications;
type PostTestCreditNotificationsRequest = ObjectWithCustomerId;
type PostTestCreditNotificationsResponse = ObjectWithCustomerId;

export const extendedApi = portalApi.injectEndpoints({
  endpoints: (builder) => ({
    getReportNotifications: builder.query<
      GetReportNotificationsResponse,
      GetReportNotificationsRequest
    >({
      query: ({ customerId }) => ({
        url: `/settings/${customerId}/connect_report`,
      }),
      extraOptions: {
        service: SERVICES.NOTIFICATIONS,
      },
      providesTags: (_, __, { customerId }) => [
        { type: TAGS.REPORT_NOTIFICATION, id: customerId },
      ],
    }),
    postReportNotifications: builder.mutation<
      PostReportNotificationsResponse,
      PostReportNotificationsRequest
    >({
      query: ({ customerId, url, email, enabled }) => ({
        url: `settings/${customerId}/connect_report`,
        method: "POST",
        body: {
          url,
          email,
          enabled,
        },
      }),
      invalidatesTags: (_, __, { customerId }) => [
        { type: TAGS.REPORT_NOTIFICATION, id: customerId },
      ],
      extraOptions: {
        service: SERVICES.NOTIFICATIONS,
      },
    }),
    putReportNotifications: builder.mutation<
      PutReportNotificationsResponse,
      PutReportNotificationsRequest
    >({
      query: ({ customerId, url, email, enabled }) => ({
        url: `settings/${customerId}/connect_report`,
        method: "PUT",
        body: {
          url,
          email,
          enabled,
        },
      }),
      onQueryStarted: async (
        { customerId, url, email, enabled },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "getReportNotifications",
            { customerId },
            (draft) => {
              Object.assign(draft, {
                url,
                email,
                enabled,
              });
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_, __, { customerId }) => [
        { type: TAGS.REPORT_NOTIFICATION, id: customerId },
      ],
      extraOptions: {
        service: SERVICES.NOTIFICATIONS,
      },
    }),
    getPayoutNotifications: builder.query<
      GetPayoutNotificationsResponse,
      GetPayoutNotificationsRequest
    >({
      query: ({ customerId }) => ({
        url: `/settings/${customerId}/connect_payout`,
      }),
      extraOptions: {
        service: SERVICES.NOTIFICATIONS,
      },
      providesTags: (_, __, { customerId }) => [
        { type: TAGS.PAYOUT_NOTIFICATIONS, id: customerId },
      ],
    }),
    postPayoutNotifications: builder.mutation<
      PostPayoutNotificationsResponse,
      PostPayoutNotificationsRequest
    >({
      query: ({ customerId, url }) => ({
        url: `/settings/${customerId}/connect_payout`,
        method: "POST",
        body: {
          url,
        },
      }),
      extraOptions: {
        service: SERVICES.NOTIFICATIONS,
      },
      invalidatesTags: (_, __, { customerId }) => [
        { type: TAGS.PAYOUT_NOTIFICATIONS, id: customerId },
      ],
    }),
    putPayoutNotifications: builder.mutation<
      PutPayoutNotificationsResponse,
      PutPayoutNotificationsRequest
    >({
      query: ({ customerId, url, enabled }) => ({
        url: `/settings/${customerId}/connect_payout`,
        method: "PUT",
        body: {
          url,
          enabled,
        },
      }),
      invalidatesTags: (_, __, { customerId }) => [
        { type: TAGS.PAYOUT_NOTIFICATIONS, id: customerId },
      ],
      extraOptions: {
        service: SERVICES.NOTIFICATIONS,
      },
    }),
    postTestPayoutNotifications: builder.mutation<
      PostTestPayoutNotificationsResponse,
      PostTestPayoutNotificationsRequest
    >({
      query: ({ customerId }) => ({
        url: `/settings/${customerId}/connect_payout/test`,
        method: "POST",
      }),
      extraOptions: {
        service: SERVICES.NOTIFICATIONS,
      },
    }),
    postTestReportNotifications: builder.mutation<
      PostTestReportNotificationsResponse,
      PostTestReportNotificationsRequest
    >({
      query: ({ customerId }) => ({
        url: `/settings/${customerId}/connect_report/test`,
        method: "POST",
      }),
      extraOptions: {
        service: SERVICES.NOTIFICATIONS,
      },
    }),
    getCreditNotifications: builder.query<
      GetCreditNotificationsResponse,
      GetCreditNotificationsRequest
    >({
      query: ({ customerId }) => ({
        url: `/settings/${customerId}/connect_credit`,
      }),
      extraOptions: {
        service: SERVICES.NOTIFICATIONS,
      },
      providesTags: (_, __, { customerId }) => [
        { type: TAGS.CREDIT_NOTIFICATIONS, id: customerId },
      ],
    }),
    postCreditNotifications: builder.mutation<
      PostCreditNotificationsResponse,
      PostCreditNotificationsRequest
    >({
      query: ({ customerId, ...body }) => ({
        url: `/settings/${customerId}/connect_credit`,
        method: "POST",
        body,
      }),
      extraOptions: {
        service: SERVICES.NOTIFICATIONS,
      },
      invalidatesTags: (_, __, { customerId }) => [
        { type: TAGS.CREDIT_NOTIFICATIONS, id: customerId },
      ],
    }),
    putCreditNotifications: builder.mutation<
      PutCreditNotificationsResponse,
      PutCreditNotificationsRequest
    >({
      query: ({ customerId, ...body }) => ({
        url: `/settings/${customerId}/connect_credit`,
        method: "PUT",
        body,
      }),
      extraOptions: {
        service: SERVICES.NOTIFICATIONS,
      },
      invalidatesTags: (_, __, { customerId }) => [
        { type: TAGS.CREDIT_NOTIFICATIONS, id: customerId },
      ],
      onQueryStarted: async (
        { customerId, url, email, enabled },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "getCreditNotifications",
            { customerId },
            (draft) => {
              Object.assign(draft, {
                url,
                email,
                enabled,
              });
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    postTestCreditNotifications: builder.mutation<
      PostTestCreditNotificationsResponse,
      PostTestCreditNotificationsRequest
    >({
      query: ({ customerId }) => ({
        url: `/settings/${customerId}/connect_credit/test`,
        method: "POST",
      }),
      extraOptions: {
        service: SERVICES.NOTIFICATIONS,
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReportNotificationsQuery,
  usePostReportNotificationsMutation,
  usePutReportNotificationsMutation,
  useGetPayoutNotificationsQuery,
  usePutPayoutNotificationsMutation,
  usePostPayoutNotificationsMutation,
  usePostTestPayoutNotificationsMutation,
  usePostTestReportNotificationsMutation,
  useGetCreditNotificationsQuery,
  usePostCreditNotificationsMutation,
  usePutCreditNotificationsMutation,
  usePostTestCreditNotificationsMutation,
} = extendedApi;

export const { getReportNotifications } = extendedApi.endpoints;
