import { ACTION_UPDATE } from "models/role/action";
import { SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const IsxPayoutConfiguration = lazyRetry(
  () => import("../pages/configuration/IsxPayout"),
  "IsxPayoutConfiguration"
);

export const configurationRoutes: Route[] = [
  {
    i18nName: "ISX payout configuration",
    path: PAGE_URLS.isxPayoutConfiguration,
    component: IsxPayoutConfiguration,
    newTheme: true,
    can: {
      do: ACTION_UPDATE,
      on: SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP,
    },
  },
];
