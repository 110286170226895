import { Config as ConfigIcon } from "@volt_developers/react-ui-components";

import { workflowsRoutes } from "modules/workflows/routes";

import type { Route } from "../RouteModel";

export const administrationRoutes: Route = {
  i18nName: "Administration",
  icon: ConfigIcon,
  path: "/administration",
  showChildrenInSidebar: true,
  children: [workflowsRoutes],
};
