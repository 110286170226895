/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  accountName: "Account name",
  accountNumber: "Account number",
  activeAccountRequiredToCreateANewPayout:
    "Active account required to create a new payout",
  amount: "Amount",
  approve: "Approve",
  approved: "Approved",
  approvedAutomatically: "Approved automatically",
  approvedByUser: "Approved by user",
  approvedPayouts: "{{amount}} payouts have been approved.",
  approvingPayouts: "Approving payouts",
  areYouSureYouWantToRejectThisPayout:
    "Are you sure you want to reject this payout?",
  beneficiary: "Beneficiary",
  beneficiaryAddress: "Beneficiary address",
  beneficiaryDetails: "Beneficiary details",
  beneficiaryFoundInHistory: "Beneficiary found in history",
  bsb: "BSB",
  buildingNumber: "Building number",
  cancel: "Cancel",
  cannotApprovePayout: "Cannot approve payout. Please try again later.",
  cannotLoadPayoutsList: "Cannot load payouts list. Please try again later.",
  cannotLoadUsernames: "Cannot load user names. Please try again later.",
  ccy: "CCY",
  changeBeneficiary: "Change beneficiary",
  closedLoop: "Closed loop",
  companyAsBeneficiary: "Company as beneficiary",
  companyName: "Company name",
  confirmationCode: "Confirmation code",
  confirmed: "Confirmed",
  country: "Country",
  createANewPayout: "Create a new payout",
  created: "Created",
  creatingANewPayout: "Creating a new payout",
  customer: "Customer",
  dashboard: "Dashboard",
  date: "Date (UTC)",
  details: "Details",
  documentId: "Document Id",
  email: "E-mail",
  failed: "Failed",
  firstName: "First name",
  iban: "IBAN",
  initiated: "Initiated",
  initiatedAtProvider: "Initiated at provider",
  isbp: "ISBP",
  knownBeneficiary: "Known beneficiary",
  lastName: "Last name",
  name: "Name",
  newBeneficiary: "New beneficiary",
  newPayout: "New payout",
  onePayoutSelected: "1 payout selected",
  openLoop: "Open loop",
  payments: "Payments",
  payout: "Payout",
  payoutBeneficiary: "Payout beneficiary",
  payoutDetails: "Payout details",
  payoutHasBeenApproved: "{{title}} has been approved.",
  payoutHasBeenRejected: "{{title}} has been rejected.",
  payoutReference: "Payout reference",
  payouts: "Payouts",
  payoutTitleHAsBeenCreated:
    "{{payoutTitle}} has been created and is waiting for approval",
  pleaseMakeSureYouHaveProvidedCorrectDetails:
    "Please make sure you have provided the correct details.",
  postalZipCode: "Postal / ZIP code",
  processing: "Processing",
  reference: "Reference",
  reject: "Reject",
  rejectedByProvider: "Rejected by provider",
  rejectedByUser: "Rejected by user",
  rejectedPayouts: "{{amount}} payouts have been rejected.",
  rejectingPayout: "Rejecting payout",
  requestedBy: "Requested by",
  requestedViaApi: "Requested via API",
  requestNewConnectAccount: "Request new connect account",
  scaRequiredForApproving: " - SCA required for approving",
  scaRequiredToApproveANewPayout: "SCA required to approve a new payout",
  scaRequiredToCreateANewPayout: "SCA required to create a new payout",
  searchBy: "Search by",
  selectAll: "Select all",
  selectCurrencyFirst: "Select currency first",
  selectThisBeneficiary: "Select this beneficiary",
  sortCode: "Sort code",
  status: "Status",
  street: "Street",
  swiftBic: "SWIFT",
  swiftCodeBic: "Swift code (BIC)",
  taxIdentificationNumber: "Document Id",
  thisPayoutWasInitiatedForAccountThatYouHaveNotReceivedPayments:
    "This payout was initiated for account that you have not received payments from in the last 12 months",
  thisPayoutWasInitiatedForAccountThatYouHaveReceivedPayments:
    "This payout was initiated for account that you have received payments from in the last 12 months",
  toApproveAPayoutYouNeedToSetupSCA:
    "To approve a payout you need to set up SCA. For multiple devices and browsers, use your phone or USB key as a passkey.",
  toCreateAPayoutYouNeedToSetUpConnectAccountFirst:
    "To create a payout you need to set up connect account first",
  townCity: "Town / City",
  transactions: "Transactions",
  type: "Type",
  voltPayoutId: "Volt payout ID",
  weWereUnableToRequest:
    "We were unable to request a payout. Please, try again later",
  xPayoutsSelected: "{{count}} payout selected",
  xPayoutsSelected_plural: "{{count}} payouts selected",
  youCanOnlyApproveClosedLoopPayouts:
    "You can only approve closed loop payouts",
  youCanOnlyApproveClosedLoopPayoutsAndRejectOpenLoopPayouts:
    "You can only approve closed loop payouts and reject open loop payouts",
  youCanOnlyApproveOpenLoopPayouts: "You can only approve open loop payouts",
  youCanOnlyApproveOrRejectClosedLoopPayouts:
    "You can only approve or reject closed loop payouts",
  youCanOnlyApproveOrRejectOpenLoopPayouts:
    "You can only approve or reject open loop payouts",
  youCanOnlyRejectClosedLoopPayouts: "You can only reject closed loop payouts",
  youCanOnlyRejectClosedLoopPayoutsAndApproveOpenLoopPayouts:
    "You can only reject closed loop payouts and approve open loop payouts",
  youCanOnlyRejectOpenLoopPayouts: "You can only reject open loop payouts",
  youDontHaveAnyPayoutsCreatedYet: "You don't have any payouts created yet.",
  youHaveNotReceivedPaymentFromThisAccount:
    "You have not received any payment from this account",
  youHaveReceivedPaymentFromThisAccount:
    "You have received payment from this account in the last {{count}} months",
  youNeedChangeCurrency: "You need to change the currency",
};
